.loaderContainer {
  display: flex;
  font-family: "poppins", sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.single {
  display: flex;
  width: 100%;

  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      justify-content: space-between; // Ensure left and right components are spaced apart
      gap: 20px;

      .left {
        flex: 2;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;
        background-image: url("../../Assets/img/single.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .editButton {
          font-family: 'Poppins', sans-serif;
          position: absolute;
          top: 0;
          right: 0;
          padding: 10px;
          text-align: center;
          font-size: 14px;
          align-items: center;
          font-weight: 600;
          color: #ffffff;
          background-color: #03345d64;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }

        .item {
          display: flex;
          // justify-content: space-between; 
          gap: 40px;
          margin-left: 20px;


          .itemImg {
            width: 400px;
            height: 400px;
            border-radius: 5%;
            object-fit: cover;
          }

          .details {
            flex-grow: 1; // Makes sure the details grow to take available space
            display: flex;
            flex-direction: column;
            gap: 10px; // Gap between detail items

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
              }
            }
          }
        }
      }

      .User-History {
        flex: 1;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}
