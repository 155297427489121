.profile {
  display: flex;
  width: 100%;

  .profileContainer {
    flex: 6;
    max-width: 90%;

    .profileContent {
      padding: 80px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      border-radius: 10px; 
      > 
      
      .searchSection {
        position: relative;  
        width: 50%;
        padding: 10px 20px; 
        display: flex;
        align-items: center;

        h1{
          font-size: 14px;

        }
    }

    
    .searchField {
        flex: 1;
        font-size: 16px; 
        margin: 10px;
        padding: 10px 40px 10px 15px;  
        border: 1px solid #d0d0d0; 
        border-radius: 50px; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); 
        outline: none; 
        transition: border-color 0.3s, box-shadow 0.3s; 
    
        &:focus {
            border-color: teal; 
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
    
        &::placeholder {
            color: #aaa; 
            font-weight: 300;
        }
    }
    
    .searchIcon {
        position: absolute;
        right: 15px;
        margin: 20px;  
    }

      div {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
      }

      .profileHeader {
        h1 {
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          color: #888;
        }
      }

      .profileActions {
        text-align: center; // Center the button

        .addUserButton {
          padding: 10px 20px;
          background-color: teal;
          border: none;
          border-radius: 5px;
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: #009688;
          }
        }
      }
    }

    .usersSection {
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding: 8px 12px;
          border: 1px solid #ddd;
          text-align: left;
        }

        tr:nth-child(even) {
          // Zebra striping for rows
          background-color: #f2f2f2;
        }

        tr:hover {
          // Hover effect for rows
          background-color: #f5f5f5;
        }

        th {
          // Styling for table headers
          background-color: #4caf50;
          color: white;
        }
        button {
          background-color: #4caf50; 
          color: white;
          border: white;
          box-shadow: #444;
          border-radius: 5px;
          padding: 5px;
  
          &:hover {
            background-color: #67b3b7; 
          }
        }
      }
   
    }

    .pagination {
      // display: flex;
      margin: 20px 0;
      // align-items: center;

      button {
        padding: 8px 12px;
        margin: 0 5px;
        cursor: pointer;
        background-color: #b0cbd1;
        border: 1px solid #ffffff;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #ddd;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: #e6e6e6;
          color: #aaa;
        }
      }

      span {
        padding: 8px 12px;
        background-color: #eee;
        border: 1px solid #ccc;
      }
    }

    .adminsSection {
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding: 8px 12px;
          border: 1px solid #ddd;
          text-align: left;
        }

        tr:nth-child(even) {
          // Zebra striping for rows
          background-color: #f2f2f2;
        }

        tr:hover {
          // Hover effect for rows
          background-color: #f5f5f5;
        }

        th {
          // Styling for table headers
          background-color: #3f51b5; // A shade of blue to differentiate from user section
          color: white;
        }
      }

      button {
        padding: 6px 12px;
        background-color: #4e73df; // A different shade of blue for staff actions
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s;

        &:hover {
          background-color: #2e59d9;
        }
      }
    }

    .activeUsersSection,
    .adminsSection,
    .usersSection,
    .adminLogsSection,
    .profileActions {
      align-items: center;
      text-align: center;

      h2 {
        font-size: 20px;
        color: #444;
        margin-bottom: 20px; // A bit of spacing before contents
      }

      ul {
        list-style-type: none; // Remove bullets from lists
        padding: 0;
        margin: 0;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px;
          border-bottom: 1px solid #f0f0f0;
          &:last-child {
            border-bottom: none;
          }
        }
        button {
          padding: 6px 12px;
          background-color: #ff6b6b;
          border: none;
          border-radius: 4px;
          color: #fff;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s;

          &:hover {
            background-color: #e74c3c;
          }
        }
      }
    }
  }
}

.readOnlyBanner {
  background-color: #fff3cd;
  color: #856404;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
}

button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}
