.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .left {
        flex: 1;
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        flex: 2 !important;
        display: grid !important;
        grid-template-columns: 1fr !important;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 90%;
              padding: 10px;
              border-top: 1px solid #7c7c7c;
              border-left: 1px solid #c3c3c3;
              border-right: 1px solid #c3c3c3;
              border-bottom: 1px solid #ddd;
              background: #fff;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input[type="number"] {
              -moz-appearance: textfield;
            }

            input[type="number"]::-ms-clear,
            input[type="number"]::-ms-reveal {
              display: none;
            }

            select {
              width: 95%;
              padding: 10px;
              border: 1px solid #c3c3c3;
              border-radius: 4px; /* Add a slight rounded corner for aesthetics */
              appearance: none; /* To remove default styling in some browsers */
              -moz-appearance: none;
              -webkit-appearance: none;
              background-color: #fff;
              cursor: pointer;

              /* Add a dropdown arrow for the select box for a visual cue */
              background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 140 140" width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M20 40l50 50 50-50H20z" fill="%23707070"/></svg>');
              background-repeat: no-repeat;
              background-position: right 10px center;
            }

            .error {
              /* This is the added error styling */
              color: red;
              font-size: 12px;
              margin-top: 5px;
            }
          }

          button {
            width: 290px;
            padding: 10px;
            text-align: center;
            align-items: center;
            border: none;
            background-color: teal;
            color: white;
            font-weight: bold;
            // display: flex;
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: 20px;
            border-top: 1px solid #3b3a3a;
            border-left: 1px solid #3b3b3b;
            border-right: 1px solid #3b3a3a;
            border-bottom: 1px solid #111111;
            clear: both;

            &:disabled {
              background-color: rgba(0, 128, 128, 0.37);
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
