.app.dark {
  background-color: #01141a;
  color: rgb(250, 247, 247);

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .table {
    background-color:  #121212;

    .tableCell {
      color: gray;
    }
  }

  .navbar {
    color: #999;
    border-color: #333;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color:  #08121bec;
    border-color: #333;

    .top {
      .logo {
        color: #999;
      }
    }
    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon {
          color: #999;
        }
      }
    }
  }

  .datatable {
    .datagrid {
      color: rgb(179, 177, 177);
      border: none;

      .viewButton,
      .deleteButton,
      .cellWithStatus {
        color: gray;
        border: none;
      }
    }
  }

  

  input{
      background-color: transparent;
  }
}

// Add these styles to handle read-only elements
.read-only {
  pointer-events: none;
  opacity: 0.7;
  cursor: not-allowed;
}

input[type="text"][readonly],
input[type="email"][readonly],
input[type="password"][readonly],
select[readonly],
textarea[readonly] {
  background-color: #f5f5f5;
  border-color: #ddd;
  cursor: not-allowed;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  border: 1px solid #999999 !important;

  &:hover, &:focus, &:active {
    background-color: #cccccc !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
}

input[disabled],
textarea[disabled],
select[disabled],
button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #f5f5f5;
  border-color: #ddd;
  pointer-events: none;
}

.transferButton.disabled,
.deleteButton.disabled,
button.disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  border: 1px solid #999999 !important;
}

// Style for links that should be disabled
a.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  color: #666666;
}

// Style for disabled action buttons in datatable
.cellAction {
  .disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
    
    &.viewButton,
    &.transferButton,
    &.deleteButton {
      background-color: #cccccc;
      color: #666666;
      border-color: #999999;
    }
  }
}

// Add these styles
.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  border: 1px solid #999999 !important;

  &:hover {
    background-color: #cccccc !important;
    cursor: not-allowed !important;
  }
}

// Style for disabled buttons in forms
form button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #cccccc;
  color: #666666;
  border: 1px solid #999999;
  pointer-events: none;
}

// Style for disabled action buttons
.cellAction {
  .disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #cccccc;
    color: #666666;
    border-color: #999999;
  }
}
