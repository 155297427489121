.datatable {
  height: 600px;
  padding: 20px;
  position: relative; 
  cursor: pointer;

  .loadingState {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.9);
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    z-index: 2;

    p {
      font-size: 18px;
      color: gray;
      text-align: center;
    }
  }

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.approved {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton, .approveButton, .transferButton, .printButton {
      padding: 2px 5px;
      border-radius: 5px;
      cursor: pointer;
    }

    .viewButton {
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
    }

    .approveButton {
      color: green;
      border: 1px dotted rgba(0, 128, 0, 0.6);
    }

    .transferButton {
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
    }  
    .printButton {
      color: green;
      border: 1px dotted rgba(60, 220, 20, 0.6);
    }
  }
}
