@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

:root {
  --primary-color: #4B8BBE;  /* A serene blue */
  --secondary-color: #6c757d;  /* A muted gray for text */
  --button-bg-color: #4B8BBE; /* Button background color */
  --button-text-color: #ffffff; /* Button text color */
  --button-hover-bg-color: #427aac; /* Manually darkened button background color for hover */
}


.home {
  display: flex;

  .homeContainer {
    flex: 6;
    padding: 0 3rem;  /* Added some horizontal padding for better spacing */

    .homeHeader {
      padding: 2rem 0;
      color: var(--primary-color);
      font-weight: 500;
      font-size: 16px;
      border-bottom: 1px solid #e5e5e5;  /* subtle bottom border for a separator */
      margin-bottom: 2rem;
    }

    .error {
      color: red;
      margin-top: 1rem;
    }
    

    .AllTransactions,
    .charts {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      gap: 20px;
    }
    .link {
      display: inline-block;
      padding: 8px 16px;
      background-color: var(--button-bg-color);
      color: var(--button-text-color);
      border-radius: 5px;
      text-decoration: none; /* Removes underline from link */
      transition: background-color 0.3s ease; /* Smooth background color transition */
    }
    
    .link:hover {
      background-color: var(--button-hover-bg-color); /* Uses a pre-defined darker color */
      cursor: pointer; /* Changes cursor to pointer on hover */
    }
    .charts {
      // background: var(--primary-color);
      color: #fff;
      border-radius: 12px;
      padding: 1.5rem;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.15);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.15);
      padding: 20px;
      margin: 20px;
      background: #fff;
      border-radius: 12px; 
      color: #4B8BBE;
      
      .listTitle {
        font-weight: 500;
        color: var(--secondary-color);
        margin-bottom: 15px;
      }
    }
  }
}
