.home {
  display: flex;

  .homeContainer {
    flex: 6;
    .widget {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;

      .left {
        flex: 5;
        border-right: 1px solid gray;

        .filterSection {
          display: flex;
          gap: 1rem;
          margin-top: 20px;
          margin-bottom: 1rem;
          input {
            padding: 0.5rem;
            border-radius: 5px;
            border: 1px solid #ccc;
            &:focus {
              border-color: #007bff;
              box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
          }
        }

        //   div {
        //     display: flex;
        //     align-items: center;

        //     span {
        //       margin-right: 10px;
        //       margin-left: 10px;
        //       font-weight: 500;
        //       color: #333;
        //     }

        //     input[type="date"],
        //     input[type="text"] {
        //       padding: 5px 10px;
        //       border: 1px solid #d4d4d4;
        //       border-radius: 5px;
        //       outline: none;
        //       transition: border-color 0.3s;

        //       &:focus {
        //         border-color: #007bff;
        //       }
        //     }
        //   }

        //   .branchFilter {
        //     input[type="text"] {
        //       padding: 6px 12px;
        //     }
        //   }
        // }

        h1 {
          font-family: "Poppins", sans-serif;
          text-transform: capitalize;
          font-weight: bold;
          margin-top: 10px;
          align-items: center;
          text-align: center;
        }

        h4 {
          font-weight: bold;
          margin-bottom: 10px;
        }

        table {
          border-collapse: collapse;
          width: 100%;
          color: #333;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          text-align: left;
          // border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
          margin: auto;
          margin-top: 10px;
          margin-bottom: 50px;

          th,
          td {
            background-color: #fff;
            padding: 10px;
            border-bottom: 1px solid #ccc;
            font-weight: bold;
          }

          th {
            background-color: #024064;
            color: #fff;
            font-weight: bold;
            padding: 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #ccc;
          }
          tr:nth-child(even) td {
            background-color: #ebf6f8;
          }

          tr:hover td {
            background-color: #ccdaff;
          }
        }
      }

      .pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        button {
          margin: 0 5px;
          padding: 5px 10px;
          cursor: pointer;
        }

        .active {
          background-color: crimson;
          color: white;
        }
      }

      .right {
        flex: 1;
        display: block;
        align-items: center;
        justify-content: center;
        margin: 180px 10px 10px 10px;
        background-color: #f0f5f7;
        padding: 20px 20px 20px 20px;
        cursor: pointer;

        h4 {
          color: #066da8;
          text-transform: uppercase;
          font-size: 12px;
          margin-bottom: 10px;
        }

        span {
          padding-left: 5px;
          color: #696b6b;
        }

        // Add a border-bottom for each div
        div:not(:last-child) {
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 15px;
          margin-bottom: 15px;
        }

        .userCount {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        }

        .sharePrice {
          margin-bottom: 15px;
        }

        .totalVolumeSection {
          margin-top: 15px;

          .totalVolume {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
}
