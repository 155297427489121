.chart {
  flex: 4;
  -webkit-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1); /* Reduced the intensity and size of the shadow for a modern, flat look */
  box-shadow: 2px 2px 6px 0 rgba(201, 201, 201, 0.1);
  padding: 20px; /* Increased padding slightly for better spacing */
  color: #888;  /* Darkened the gray a bit */
  background: #fff; /* Added a background color to make it stand out more */
  border-radius: 10px; /* Rounded corners for a more modern look */

  .title {
    font-weight: 600; /* Made the title bolder */
    font-size: 1.2em; /* Increased the title size slightly */
    margin-bottom: 20px;
  }

  .chartGrid {
    stroke: rgb(228, 225, 225);
  }
}
