.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../Assets/img/background.png") no-repeat center center fixed;
  background-size: cover;
  align-items: center;
  justify-content: center;

  .write {
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  h2 {
    text-align: center;
    color: #000; /* Or any color that will be visible on your background */
    font-size: 2em;
    margin-bottom: 10px;
  }

  span {
    text-align: center;
    color: #1926db; /* Or any color that will be visible on your background */
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  p {
    text-align: center;
    color: #1926db; /* Or any color that will be visible on your background */
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 20px;
    //   border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    input {
      margin: 10px 0;
      padding: 10px;
      border-top: 1px solid #7c7c7c;
      border-left: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;
      border-bottom: 1px solid #ddd;
      border-radius: 5px;
      width: 100%;
      box-sizing: border-box;
    }

    button {
      margin: 10px 0;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: blue;
      color: white;
      font-weight: bold;
      width: 100%;
      cursor: pointer;

      &:hover {
        background-color: darkblue;
      }
    }

    span {
      color: red;
    }
  }
}
