.printuser {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 5px dashed #ddd;
  background-color: #fff; /* Give it a white background */
  width: 80%;
  margin: 20px auto; /* Center the receipt on the page */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Slight shadow for depth */
}

.printuser, .header, .userdata, .userdata p {
  font-family: 'Courier New', Courier, monospace;  /* this will give it a typewriter look */
  font-style: italic;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;  /* this will center the items horizontally */
}


.printuser .company-logo {
  width: 150px; /* Adjust based on your logo's aspect ratio */
  height: auto;
  margin-bottom: 15px;
}

.printuser h1 {
  font-family: 'Courier New', Courier, monospace;  /* this will give it a typewriter look */
  margin-bottom: 10px;
  color: #333;
  font-size: 2em;
}

.printuser h2 {
  font-family: 'Courier New', Courier, monospace;  /* this will give it a typewriter look */
  margin-bottom: 15px;
  color: #666; /* A bit lighter color */
  font-size: 1.5rem;
  text-align: center;
}

.userdata {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  border: 2px dashed #ddd;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}

.userdata p {
  margin: 10px 0;
  line-height: 1.6;
  font-size: 1.1em;
  color: #555;
}

.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.details p {
  flex-basis: 48%; /* slightly less than 50% to account for any potential box-sizing/padding/margin issues */
  margin: 10px 0; /* Adjust as needed */
}


.userdata strong {
  margin-right: 10px;
  color: #333;
  font-weight: 600; /* Slightly bolder */
}

.printuser button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add transform for click effect */
}

.printuser button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Slight increase in size when hovered */
}

.printuser button:active {
  transform: scale(1); /* Return to normal size when clicked */
}
