.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;
    max-width: 90%;

    .content {
      margin: 10px 50px 10px 50px;
      width: 70%;
      padding: 80px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      border-radius: 10px; /* Rounded corners */
      align-items: center;

    h1 {
      font-size: 2rem;
      margin-bottom: 2rem;
      color: #333;
    }

    .userForm {
      align-items: center;
      width: 70%;

      .formInput {
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
        label {
          font-weight: 600;
          margin-bottom: 0.5rem;
          color: #555;
        }
        input {
          padding: 0.5rem 1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 1rem;
          &:focus {
            outline-color: #007bff;
            border-color: #007bff;
          }
        }
        select {
          padding: 0.5rem 1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 1rem;
        }
        .error {
          margin-top: 0.5rem;
          color: red;
        }
      }

      .formButton {
        display: flex;
        justify-content: center;
        button {
          padding: 0.5rem 2rem;
          font-size: 1rem;
          background-color: #007bff;
          border: none;
          border-radius: 5px;
          color: white;
          cursor: pointer;
          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }
  }
  }
}
