.user-log {
  position: relative;
  background-color: #f7f9fc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
  max-width: 600px;
  margin: 40px auto;

  h3 {
    border-bottom: 2px solid #e4e8ed;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
    margin-left: 20px;
  }
  .backArrow {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #666;
    position: absolute;
    margin-right: 10px;
    top: 20px;
    left: 20px;
    padding: 0;
    outline: none;

    &:hover,
    &:focus {
      color: #333;
    }
  }

  .log-details {
    p {
      font-size: 1em;
      color: #666;
      margin-bottom: 10px;
      line-height: 1.5;

      strong {
        color: #333;
        margin-right: 10px;
      }
    }
  }
}
