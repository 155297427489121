.user-history {
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #024064;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px auto 50px;
    font-size: 14px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #333;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    thead {
      background-color: #f2f2f2;

      th {
        padding: 10px 15px;
        background-color: #024064;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #ccc;
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #ebf6f8;
        }

        &:hover {
          background-color: #ccdaff;
        }

        td {
          padding: 10px 15px;
          border-bottom: 1px solid #ccc;
          cursor: pointer;

          .log-icon {
            transition: color 0.3s ease, transform 0.3s ease;

            &:hover {
              cursor: pointer;
              color: #86a3f3;
              transform: scale(1.5);
            }
          }
        }
      }
    }
  }

  /* Pagination styling */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    button {
      padding: 8px 12px;
      margin: 0 5px;
      border: 1px solid #ffffff;
      background-color: #b0cbd1;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }

      &:disabled {
        background-color: #e6e6e6;
        color: #aaa;
        cursor: not-allowed;
      }
    }

    span {
      padding: 8px 12px;
      background-color: #eee;
      border: 1px solid #ccc;
    }
  }

  p {
    text-align: center;
    font-style: italic;
    font-size: 16px;
    color: #666;
  }

  /* Styling for the date filter section */
  .filterSection {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    input[type="date"] {
      padding: 10px;
      margin: 0 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #f9f9f9;
      font-family: "Poppins", sans-serif;
      color: #333;
      cursor: pointer;
      transition: border 0.3s ease, background-color 0.3s ease;

      &:hover {
        border-color: #024064;
        background-color: #f1f8fc;
      }
    }

    button {
      padding: 10px 20px;
      border: none;
      background-color: #024064;
      color: #fff;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #026899;
      }
    }
  }

  /* Export to CSV styling */
  a {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 0;
    background-color: #024064;
    color: white;
    text-align: center;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #026899;
    }
  }
}
